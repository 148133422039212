<template>
  <el-dialog
    v-model="showAddChartDialog"
    width="620px"
    align-center
    :destroy-on-close="true"
    :show-close="true"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-dashboard-add-chart-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <div class="elv-dashboard-add-chart-dialog-header-title">
        <h4 :id="titleId">
          {{ t('button.addChart') }}
        </h4>
      </div>
    </template>
    <div class="elv-dashboard-add-chart-dialog-content">
      <el-form :model="customAddParams" :rules="rules" label-position="top">
        <el-form-item :label="t('project.dashboard.metric')" prop="name">
          <ElvSelect
            v-model="customAddParams.metric"
            width="572px"
            :placeholder="`${t('common.select')}...`"
            :popper-append-to-body="false"
            :options="metricList"
            style="width: 100%"
            @change="onChangeMetric"
          />
        </el-form-item>
        <el-form-item :label="t('common.preview')" class="elv-dashboard-add-chart-dialog-content-target-type">
          <el-radio-group v-model="customAddParams.targetType" fill="#1753EB" @change="onChangeChartPreviewType">
            <el-radio value="TREND">{{ t('project.dashboard.trends') }}</el-radio>
            <el-radio value="DISTRIBUTION" :disabled="customAddParams.metric === 'TOTAL_NET_INCOME'">{{
              t('project.dashboard.distribution')
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <div class="elv-dashboard-add-chart-dialog-content-preview">
            <el-input
              v-model="customAddParams.name"
              minlength="1"
              maxlength="50"
              :placeholder="t('placeholder.inputChartName')"
            ></el-input>
            <div class="elv-dashboard-add-chart-dialog-content-preview-filter">
              <ChartConditionSelect
                v-if="customAddParams.metric"
                v-model:timeModel="customAddParams.config.timeRange"
                v-model:currencyModel="customAddParams.config.currencyList"
                :dashboardModuleType="props.dashboardModuleType"
                :currencyList="filterCurrencyList"
                :showFilterDateRange="filterCondition.showFilterDataRange"
                :showFilterCurrency="filterCondition.showFilterCurrency"
                :showFilterViewBy="filterCondition.showFilterViewBy"
                :targetType="customAddParams.targetType"
                :showTrend="filterCondition.showTrend"
                :showDistribution="filterCondition.showDistribution"
                @onChangeSelectTimeRange="queryPreviewChartData"
                @onChangeSelectCurrency="onChangeSelectCurrency"
              />
              <ChangeChartTypeButton
                class="elv-dashboard-add-chart-screening-button"
                :chartType="currentShowChartType"
                @onChangeSelectChartType="onChangeSelectChartType"
              />
            </div>
            <div v-loading="loadPreviewLoading" class="elv-dashboard-add-chart-dialog-loading-chart">
              <component
                :is="currentChartComponent.component"
                v-if="!loadPreviewLoading && editChartData && !isEmpty(editChartData.chartValueList)"
                :chartWidth="currentChartComponent.width"
                :chartHeight="currentChartComponent.height"
                :data="editChartData || null"
                :timeRange="customAddParams.config.timeRange"
              ></component>
              <div v-else class="elv-empty-chart-preview">
                <SvgIcon name="dashboard-empty" :width="48" :height="48" fill="#DDE1E6" />
                <span>{{ t('common.noData') }}</span>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <div class="elv-dashboard-add-chart-dialog-footer-content">
        <el-button
          type="primary"
          round
          :loading="isAddLoading"
          :disabled="isUnEnableAdd"
          class="elv-add-chart-dialog-footer__confirm"
          :class="{ 'elv-add-chart-dialog-footer__cancel': isUnEnableAdd }"
          @click="onClickConfirmSaveChart"
        >
          {{ t('button.save') }}</el-button
        >
      </div>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import DashboardApi from '@/api/DashboardApi'
import { cloneDeep, isEmpty } from 'lodash-es'
import PieChart from './ChartWidget/PieChart.vue'
import LineChart from './ChartWidget/LineChart.vue'
import { ElMessage, FormRules } from 'element-plus'
import ElvSelect from '@/components/Base/ElvSelect.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { useDashboardStore } from '@/stores/modules/dashboard'
import HorizontalChart from './ChartWidget/HorizontalChart.vue'
import ChangeChartTypeButton from './ChangeChartTypeButton.vue'
import VerticalBarChart from './ChartWidget/VerticalBarChart.vue'
import ChartConditionSelect from './ChartConditionSelect.vue'
import { DashboardChartItemTypes, DashboardCurrencyTypes } from '#/DashboardTypes'
import {
  DashboardChartType,
  DashboardModuleType,
  ReportSummaryIndicatorList,
  TreasurySummaryIndicatorList,
  timeGranularity,
  viewByList
} from '@/config/dashboard'

const props = defineProps({
  dashboardModuleType: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['onClickConfirmHandler'])

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const dashboardStore = useDashboardStore()

const isEditChart = ref(false)
const isAddLoading = ref(false)
const currentShowChartType = ref('')
const showAddChartDialog = ref(false)
const loadPreviewLoading = ref(false)
const filterCurrencyList = ref<DashboardCurrencyTypes[]>([])
const editChartData = ref<any>()

const rules = reactive<FormRules>({
  metric: {
    required: true,
    trigger: 'blur',
    message: 'metric is required'
  },
  chartName: {
    required: true,
    trigger: 'blur',
    message: 'chartName is required'
  }
})

const componentMap = {
  [DashboardChartType.lineChart.type]: {
    component: LineChart,
    width: '538px',
    height: '255px'
  },
  [DashboardChartType.pieChart.type]: {
    component: PieChart,
    width: '238px',
    height: '255px'
  },
  [DashboardChartType.barVerticalChart.type]: {
    component: VerticalBarChart,
    width: '538px',
    height: '255px'
  },
  [DashboardChartType.barHorizontalChart.type]: {
    component: HorizontalChart,
    width: '538px',
    height: '255px'
  }
}

const customAddParams = reactive({
  sourceType: '',
  name: '',
  metric: '',
  targetType: '',
  showType: '',
  config: {
    timeRange: '',
    currencyList: [] as string[]
  }
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const isUnEnableAdd = computed(() => {
  return !customAddParams.name || !customAddParams.metric
})

const metricList = computed(() => {
  return props.dashboardModuleType === DashboardModuleType.treasury.value
    ? [TreasurySummaryIndicatorList[0]]
    : ReportSummaryIndicatorList
})

const currentChartComponent = computed(() => {
  return componentMap[currentShowChartType.value] || { component: LineChart }
})

const filterCondition = computed(() => {
  if (props.dashboardModuleType === DashboardModuleType.treasury.value) {
    return {
      showFilterDataRange: customAddParams.targetType === 'TREND',
      showFilterCurrency: true,
      showFilterViewBy: false,
      showTrend: false,
      showDistribution: false
    }
  }
  if (!customAddParams.metric) {
    return {
      showFilterDataRange: false,
      showFilterCurrency: true,
      showFilterViewBy: true,
      showTrend: false,
      showDistribution: false
    }
  }
  const currentMetric = ReportSummaryIndicatorList.find((item) => item.value === customAddParams.metric)
  if (!currentMetric) {
    return {
      showFilterDataRange: false,
      showFilterCurrency: true,
      showFilterViewBy: false,
      showTrend: false,
      showDistribution: false
    }
  }
  return {
    showFilterDataRange: false,
    showFilterCurrency: currentMetric?.showCurrency || false,
    showFilterViewBy: currentMetric?.showViewBy || false,
    showTrend: currentMetric.showTrend,
    showDistribution: currentMetric.showDistribution
  }
})

/**
 * 初始化日期颗粒度
 */
const initViewByDefaultDate = (timeRange: string = '') => {
  if (props.dashboardModuleType === DashboardModuleType.treasury.value) {
    customAddParams.config.timeRange = timeRange || timeGranularity[0].value
  } else {
    customAddParams.config.timeRange = !timeRange ? viewByList[2].value : timeRange || viewByList[2].value
  }
}

/**
 * 初始化当前选中的图表模式
 */
const resetChartShowType = () => {
  Object.keys(DashboardChartType).forEach((key) => {
    const chartType = DashboardChartType[key]
    if (
      chartType.dashboardType.includes(customAddParams.showType) &&
      chartType.dashboardType.includes(customAddParams.targetType)
    ) {
      currentShowChartType.value = chartType.type
    }
  })
}

/**
 * 获取添加新图标 预览数据
 */
const queryPreviewChartData = async () => {
  loadPreviewLoading.value = true
  try {
    if (props.dashboardModuleType === DashboardModuleType.treasury.value) {
      const treasuryData = await DashboardApi.previewTreasuryChartData(entityId.value, {
        targetType: customAddParams.targetType,
        config: {
          timeRange: customAddParams.config.timeRange,
          currencyList: customAddParams.config.currencyList.filter((item) => item !== 'All_Currency')
        }
      })
      editChartData.value.chartValueList = treasuryData.data?.chartValueList ?? (treasuryData.data || [])
    } else {
      if (!customAddParams.metric) {
        return
      }
      const reportFilterInfo: any = dashboardStore.getCurrentReportFilterInfo(entityId.value)
      const params = {
        targetType: customAddParams.targetType,
        metric: customAddParams.metric,
        dateRange: reportFilterInfo?.dateRange || [],
        period: reportFilterInfo?.period.toLocaleUpperCase() || 'MONTH',
        config: {
          viewTimeRange: customAddParams.config.timeRange,
          currencyList: customAddParams.config.currencyList.filter((item) => item !== 'All_Currency')
        }
      }
      const treasuryData = await DashboardApi.previewReportsChartData(entityId.value, params)
      editChartData.value.chartValueList = treasuryData.data?.chartValueList ?? (treasuryData.data || [])
    }
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    loadPreviewLoading.value = false
  }
}

/**
 * 重置添加图表名称
 */
const resetChartName = () => {
  if (!isEditChart.value) {
    const suffix =
      customAddParams.targetType === 'TREND' ? t('project.dashboard.trends') : t('project.dashboard.distribution')
    const metricInfo =
      props.dashboardModuleType === DashboardModuleType.treasury.value
        ? TreasurySummaryIndicatorList.find((item) => item.value === customAddParams.metric)
        : ReportSummaryIndicatorList.find((item) => item.value === customAddParams.metric)
    customAddParams.name = metricInfo ? `${t(metricInfo.label)} ${suffix}` : ''
  }
  initViewByDefaultDate()
}

/**
 * 切换Metric指标
 */
const onChangeMetric = () => {
  // Profit指标没有分布图需要切换显示类型
  if (customAddParams.metric === 'TOTAL_NET_INCOME') {
    customAddParams.showType = 'LINE'
    customAddParams.targetType = 'TREND'
    currentShowChartType.value = 'LINE_CHART'
  }
  resetChartName()
  queryPreviewChartData()
}

/**
 * 切换币种信息
 */
const onChangeSelectCurrency = (currencyList: string[]) => {
  if (currencyList.length === 0) {
    customAddParams.config.currencyList = ['All_Currency']
    queryPreviewChartData()
    return
  }
  if (currencyList[currencyList.length - 1] === 'All_Currency') {
    customAddParams.config.currencyList = ['All_Currency']
  } else {
    customAddParams.config.currencyList = currencyList.filter((item) => item !== 'All_Currency')
  }
  queryPreviewChartData()
}

/**
 * 切换添加Chart图表弹窗显示状态
 * @param data 编辑Chart图表数据
 */
const changeDialogStatus = (data?: DashboardChartItemTypes, timeRange?: string) => {
  showAddChartDialog.value = !showAddChartDialog.value
  if (showAddChartDialog.value) {
    if (data) {
      isEditChart.value = true
      editChartData.value = cloneDeep(data)
      customAddParams.metric = editChartData.value.metric
      customAddParams.name = editChartData.value.name
      customAddParams.targetType = editChartData.value.targetType
      customAddParams.showType = editChartData.value.showType
      customAddParams.config.currencyList = editChartData.value.config?.currencyList?.length
        ? editChartData.value.config?.currencyList
        : ['All_Currency']
      filterCurrencyList.value = editChartData.value.configCurrencyList || []
      initViewByDefaultDate(timeRange)
      resetChartShowType()
      return
    }
    isEditChart.value = false
    customAddParams.metric =
      props.dashboardModuleType === DashboardModuleType.treasury.value ? TreasurySummaryIndicatorList[0].value : ''
    customAddParams.name = customAddParams.metric ? t(TreasurySummaryIndicatorList[0].label) : ''
    customAddParams.targetType = 'TREND'
    customAddParams.showType = 'LINE'
    customAddParams.config.currencyList = ['All_Currency']
    filterCurrencyList.value = []
    editChartData.value = {
      chartId: '',
      name: '',
      targetType: '',
      showType: '',
      metric: '',
      sourceType: '',
      config: {
        timeRange: '',
        currencyList: []
      },
      chartValueList: []
    }
    initViewByDefaultDate()
    resetChartShowType()
    queryPreviewChartData()
  }
}

/**
 * 切换图表预览展示类型
 */
const onChangeChartPreviewType = (value: any) => {
  if (value === 'TREND') {
    currentShowChartType.value = 'LINE_CHART'
    customAddParams.showType = 'LINE'
  } else {
    currentShowChartType.value = 'PIE_CHART'
    customAddParams.showType = 'PIE'
  }
  resetChartName()
  queryPreviewChartData()
}

/**
 * 切换预览图表展示模式
 */
const onChangeSelectChartType = (checkType: string) => {
  Object.keys(DashboardChartType).forEach((key) => {
    const chartType = DashboardChartType[key]
    if (chartType.type === checkType) {
      customAddParams.showType = chartType.showType
      currentShowChartType.value = chartType.type
    }
  })
}

/**
 * 确认添加Chart图表
 */
const onClickConfirmSaveChart = async () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.dashboard?.update
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  isAddLoading.value = true
  customAddParams.sourceType = props.dashboardModuleType
  const params: any = cloneDeep(customAddParams)
  if (isEditChart.value) {
    delete params.sourceType
  }
  params.config.currencyList = customAddParams.config.currencyList.filter((item) => item !== 'All_Currency')
  delete params.config?.viewTimeRange
  if (props.dashboardModuleType === DashboardModuleType.reports.value) {
    delete params.config?.timeRange
  }
  try {
    if (isEditChart.value) {
      await DashboardApi.editChartData(entityId.value, editChartData.value?.chartId || '', params)
    } else {
      await DashboardApi.addChartData(entityId.value, params)
    }
    changeDialogStatus()
    ElMessage.success(t('common.successfully'))
    emit('onClickConfirmHandler')
  } catch (error: any) {
    ElMessage.error(error.message)
    isAddLoading.value = false
  }
}

/**
 * 关闭添加Chart图表弹窗数据初始化
 */
const onCloseDialog = () => {
  customAddParams.name = ''
  customAddParams.metric = ''
  isAddLoading.value = false
  showAddChartDialog.value = false
  isEditChart.value = false
}

defineExpose({
  changeDialogStatus
})
</script>

<style lang="scss">
.elv-dashboard-add-chart-dialog {
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    border-bottom: 1px solid #edf0f3;
    padding: 0px;
  }

  .elv-dashboard-add-chart-dialog-header-title {
    min-height: 54px;
    text-align: center;
    font-size: 16px;
    color: #0e0f11;
    line-height: 24px;
    height: 49px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 24px;
  }

  .elv-dashboard-add-chart-dialog-content {
    padding: 18px 24px 0px 24px;
    position: relative;

    .elv-base-extend-select {
      .elv-base-extend-select-popper {
        .el-select-dropdown__wrap {
          ul > li {
            padding: 0px 8px;
          }
        }

        .el-select-dropdown__footer {
          padding: 0px;
        }
      }
    }

    .elv-dashboard-add-chart-dialog-content-target-type {
      display: flex;
      align-items: center;
      margin-top: 4px;

      .el-form-item__content {
        display: flex;
        justify-content: flex-end;
      }

      .el-form-item__label {
        line-height: 28px !important;
        margin-bottom: 0px !important;
      }

      .el-radio-group {
        height: 28px;

        .el-radio {
          height: 28px;

          .el-radio__label {
            height: 28px;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            color: #1e2024;
          }

          .el-radio__inner {
            border-color: #dde1e6;
          }

          .el-radio__input.is-checked .el-radio__inner {
            border-color: #1753eb;
            background-color: #1753eb;
          }
        }
      }
    }

    .elv-dashboard-add-chart-dialog-content-preview {
      width: 572px;
      height: 390px;
      background-color: #f9fafb;
      border: 1px solid #edf0f3;
      border-radius: 8px;
      padding: 16px;
      position: relative;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;

      .elv-dashboard-add-chart-dialog-content-preview-filter {
        margin: 20px 0 16px;
        display: flex;
        align-items: center;
        position: relative;
        height: 28px;
      }

      .elv-dashboard-add-chart-time-range-select {
        height: 15px;

        .el-select__wrapper {
          padding: 0px;
          border: none;
          box-shadow: none;
          background-color: transparent;

          .elv-select-selected-item-tags-single {
            font-weight: 400;
            font-size: 12px;
            color: #636b75;
          }

          .el-select__suffix {
            margin-top: 5px;

            .el-icon {
              margin-left: 0px;
            }
          }
        }
      }

      .elv-dashboard-add-chart-screening-button {
        position: absolute;
        right: 0px;
      }

      .elv-dashboard-add-chart-dialog-loading-chart {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .elv-empty-chart-preview {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          svg {
            color: #d0d4d9;
            margin-bottom: 8px;
          }

          span {
            color: #636b75;
            text-align: center;
            font-family: 'Plus Jakarta Sans';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
          }
        }

        .el-loading-mask {
          background-color: #f9fafb;
        }
      }
    }
  }

  .elv-dashboard-add-chart-dialog-footer-content {
    min-height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 113px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;
    }

    .elv-add-chart-dialog-footer__cancel {
      background: #edf0f3;
      color: #636b75;
      pointer-events: none;
    }
  }
}
</style>
