<template>
  <el-radio-group
    v-model="selectedChartType"
    class="elv-dashboard-change-chart-type-screening-button"
    size="large"
    @change="onChangeSelectChartType"
  >
    <el-radio-button v-for="item in showChartBtnTypeList" :key="item.type" :value="item.type">
      <SvgIcon
        :name="item.icon"
        :width="15"
        :height="15"
        :fill="item.type === selectedChartType ? '#1753EB' : '#AAAFB6'"
      />
    </el-radio-button>
  </el-radio-group>
</template>

<script setup lang="ts">
import { DashboardChartType } from '@/config/dashboard'
import { DashboardChartItemTypes } from '#/DashboardTypes'

const props = defineProps({
  chartType: {
    type: String,
    require: true,
    default: ''
  },
  chartData: {
    type: Object,
    default: {} as DashboardChartItemTypes
  }
})

const emit = defineEmits(['onChangeSelectChartType'])

const selectedChartType = ref('')

const showTypeList = Object.freeze([
  [DashboardChartType.lineChart, DashboardChartType.barVerticalChart],
  [DashboardChartType.pieChart, DashboardChartType.barHorizontalChart]
])

const showChartBtnTypeList = computed(() => {
  return showTypeList.find((item) => item.some((chartItem) => chartItem.type === props.chartType)) || []
})

/**
 * 切换显示的图标类型
 * @param value 选择的显示的图表类型
 */
const onChangeSelectChartType = (value: any) => {
  emit('onChangeSelectChartType', value)
}

watch(
  () => props.chartType,
  (newVal) => {
    selectedChartType.value = newVal
  },
  { immediate: true }
)
</script>

<style lang="scss">
.elv-dashboard-change-chart-type-screening-button {
  .el-radio-button__inner {
    padding: 0px;
    width: 30px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .el-radio-button.is-active .el-radio-button__original-radio:not(:disabled) + .el-radio-button__inner {
    background-color: #e5edff;
    border-color: #dde1e6;
    box-shadow: none;
  }
}
</style>
