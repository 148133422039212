import { $t } from '@/i18n/index'
import { DashboardChartTypes } from '#/DashboardTypes'

export const DashboardModuleType = {
  treasury: {
    label: $t('project.nav.treasury'),
    value: 'TREASURY_BALANCE'
  },
  reports: {
    label: $t('common.reports'),
    value: 'REPORT'
  }
}

export const datePeriodList = [
  {
    label: $t('common.day'),
    value: 'DAY'
  },
  {
    label: $t('common.Month'),
    value: 'MONTH'
  },
  {
    label: $t('common.quarter'),
    value: 'QUARTER'
  },
  {
    label: $t('common.year'),
    value: 'YEAR'
  }
]

export const timeGranularity = [
  {
    label: $t('project.dashboard.last30Days'),
    value: 'LAST_30_DAYS'
  },
  {
    label: $t('project.dashboard.thisMonth'),
    value: 'THIS_MONTH'
  },
  {
    label: $t('project.dashboard.thisQuarter'),
    value: 'THIS_QUARTER'
  }
]

export const viewByList = [
  {
    label: $t('project.dashboard.viewByQuarter'),
    value: 'VIEW_BY_QUARTER'
  },
  {
    label: $t('project.dashboard.viewByMonth'),
    value: 'VIEW_BY_MONTH'
  },
  {
    label: $t('project.dashboard.viewByDay'),
    value: 'VIEW_BY_DAY'
  }
]

export const TreasurySummaryIndicatorList = [
  {
    label: $t('project.dashboard.totalBalance'),
    value: 'TOTAL_BALANCE',
    showCurrency: true,
    showViewBy: false,
    showTrend: false,
    showDistribution: false
  },
  {
    label: $t('project.dashboard.fiatBalance'),
    value: 'FIAT_BALANCE',
    showCurrency: true,
    showViewBy: false,
    showTrend: false,
    showDistribution: false
  },
  {
    label: $t('project.dashboard.cryptoBalance'),
    value: 'CRYPTO_BALANCE',
    showCurrency: true,
    showViewBy: false,
    showTrend: false,
    showDistribution: false
  }
]

export const ReportSummaryIndicatorList = [
  {
    label: $t('project.dashboard.totalAssets'),
    value: 'TOTAL_ASSET',
    showCurrency: true,
    showViewBy: true,
    showTrend: false,
    showDistribution: false
  },
  {
    label: $t('project.dashboard.totalLiabilities'),
    value: 'TOTAL_LIABILITY',
    showCurrency: true,
    showViewBy: true,
    showTrend: false,
    showDistribution: false
  },
  {
    label: $t('project.dashboard.totalEquity'),
    value: 'TOTAL_EQUITY',
    showCurrency: false,
    showViewBy: true,
    showTrend: false,
    showDistribution: false
  },
  {
    label: $t('project.dashboard.revenue'),
    value: 'TOTAL_REVENUE',
    showCurrency: false,
    showViewBy: true,
    showTrend: true,
    showDistribution: false
  },
  {
    label: $t('project.dashboard.expense'),
    value: 'TOTAL_EXPENSE',
    showCurrency: false,
    showViewBy: true,
    showTrend: true,
    showDistribution: false
  },
  {
    label: $t('project.dashboard.profit'),
    value: 'TOTAL_NET_INCOME',
    showCurrency: false,
    showViewBy: false,
    showTrend: true,
    showDistribution: false
  }
]

export const DashboardChartType: DashboardChartTypes = {
  lineChart: {
    type: 'LINE_CHART',
    showType: 'LINE',
    icon: 'chart-line-gray',
    dashboardType: ['TREND', 'LINE'],
    changeChartType: 'BAR_VERTICAL_CHART'
  },
  barVerticalChart: {
    type: 'BAR_VERTICAL_CHART',
    showType: 'BAR',
    icon: 'chart-column',
    dashboardType: ['TREND', 'BAR'],
    changeChartType: 'LINE_CHART'
  },
  barHorizontalChart: {
    type: 'BAR_HORIZONTAL_CHART',
    showType: 'BAR',
    icon: 'chart-row',
    dashboardType: ['DISTRIBUTION', 'BAR'],
    changeChartType: 'PIE_CHART'
  },
  pieChart: {
    type: 'PIE_CHART',
    showType: 'PIE',
    icon: 'chart-pie',
    dashboardType: ['DISTRIBUTION', 'PIE'],
    changeChartType: 'BAR_HORIZONTAL_CHART'
  }
}

export const DashboardChartColorType = [
  ['#124BDD', '#2F64EE', '#5883F1', '#82A2F5', '#ACC1F8', '#D5E0FC'],
  ['#7242F2', '#8961F4', '#A181F6', '#B8A0F8', '#D0C0FB', '#E7DFFD'],
  ['#10C4EC', '#36CFF1', '#5ED9F4', '#86E2F7', '#AEECFA', '#D7F5FC'],
  ['#EC6F10', '#F18736', '#F49F5E', '#F7B786', '#FACFAE', '#FCE7D7'],
  ['#FFBB0E', '#FFC636', '#FFD25E', '#FFDD87', '#FFE8AF', '#FFF4D7'],
  ['#ABB5C3', '#B9C1CD', '#C7CED7', '#D5DAE1', '#E3E6EB', '#F1F3F5']
]
