<template>
  <div class="elv-dashboard-module-nav">
    <nav class="elv-dashboard-module-nav-header">
      <ChartMenus
        ref="dashboardMenusRef"
        :title="t(props.moduleTypeInfo.label)"
        :isShowScreen="props.moduleTypeInfo.value === DashboardModuleType.reports.value"
        @onChangDateTime="onChangDateTime"
      />
      <el-button v-if="disabledAddBtn" class="elv-dashboard-module-nav-add-chart-btn" round @click="addNewChart">
        <SvgIcon name="add--alt" :width="16" :height="16" />
        {{ t('button.addChart') }}</el-button
      >
    </nav>
    <div
      v-if="showSummaryList && !!summaryList.length"
      v-loading="updateChartListLoading"
      class="elv-dashboard-module-nav-body"
    >
      <div v-if="showSummaryList" class="elv-dashboard-module-nav-summary">
        <SummaryItem
          v-for="item in summaryList"
          :key="item.chartId"
          :summaryData="item"
          :summaryType="props.moduleTypeInfo.value"
          @onUpdateSummaryList="onUpdateChartList"
        />
      </div>
      <div
        v-if="!!sortableChartList.length"
        class="elv-dashboard-module-nav-chart-list"
        :aria-label="moduleTypeInfo?.value"
      >
        <CharItem
          v-for="item in sortableChartList"
          :key="item.chartId"
          :dashboardModuleType="props.moduleTypeInfo.value"
          :chartData="item"
          :changePeriodStatus="changePeriodStatus"
          @onDeleteChartItem="onDeleteChartItem"
          @onEditChartItem="onEditChartItem"
        />
      </div>
    </div>
    <div v-else v-loading="updateChartListLoading" class="elv-dashboard-module-nav-empty">
      <SvgIcon name="dashboard-empty" :width="35" :height="35" fill="#DDE1E6" />
      <p>
        {{ t('common.noData')
        }}<span @click="onJumpLink">{{
          props.moduleTypeInfo?.value === 'TREASURY_BALANCE'
            ? t('button.addAccountsInTreasury')
            : t('button.generateReportsInReports')
        }}</span>
      </p>
    </div>
  </div>
  <AddChartWidgetDialog
    ref="addChartWidgetDialogRef"
    :dashboardModuleType="props.moduleTypeInfo.value"
    @onClickConfirmHandler="onSaveChartItem"
  />

  <ElvMessageBox
    ref="deleteMessageBoxRef"
    :showCancelButton="true"
    :cancelButtonText="t('button.cancel')"
    :confirmButtonText="t('button.delete')"
    :loading="deleteLoading"
    :title="t('title.deleteChart')"
    @onConfirmEvent="onConfirmDeleteEvent"
    @onCancelEvent="onCheckDeleteMessageBox"
  >
    <template #content>
      <span class="elv-confirm-info-header__title">
        {{ t('message.deleteChartMessage', { name: currentChartData?.name }) }}
      </span>
    </template>
  </ElvMessageBox>
</template>

<script setup lang="ts">
import { ElMessage } from 'element-plus'
import DashboardApi from '@/api/DashboardApi'
import ChartMenus from './ChartMenus.vue'
import CharItem from './CharItem.vue'
import { DashboardModuleType } from '@/config/dashboard'
import { useEntityStore } from '@/stores/modules/entity'
import AddChartWidgetDialog from './AddChartWidgetDialog.vue'
import SummaryItem from './SummaryItem.vue'
import { useDashboardStore } from '@/stores/modules/dashboard'
import { DashboardChartItemTypes, ReportChartsFilterTypes } from '#/DashboardTypes'

const props = defineProps({
  moduleTypeInfo: {
    type: Object,
    required: true
  }
})

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const entityStore = useEntityStore()
const dashboardStore = useDashboardStore()

const changePeriodStatus = ref(false)
const dashboardMenusRef = ref()
const addChartWidgetDialogRef = ref()
const deleteMessageBoxRef = ref()
const deleteLoading = ref(false)
const currentChartData = ref<DashboardChartItemTypes>({} as DashboardChartItemTypes)
const updateChartListLoading = ref(false)

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const summaryList = computed(() => {
  return props.moduleTypeInfo.value === DashboardModuleType.treasury.value
    ? dashboardStore.treasuryData.fixedChartList
    : dashboardStore.reportsData.fixedChartList
})

const sortableChartList = computed(() => {
  return props.moduleTypeInfo.value === DashboardModuleType.treasury.value
    ? dashboardStore.treasuryData.sortableChartList
    : dashboardStore.reportsData.sortableChartList
})

const showSummaryList = computed(() => {
  return (
    !!summaryList.value.length &&
    summaryList.value.some((summary) => summary?.chartValueList?.[0]?.totalAmountFC !== null)
  )
})

const disabledAddBtn = computed(() => {
  return (
    !(
      ['MEMBER', ''].includes(currentEntityPermission.value?.role) && !currentEntityPermission.value?.dashboard?.update
    ) && showSummaryList.value
  )
})

/**
 * 添加新的Chart表格
 */
const addNewChart = () => {
  addChartWidgetDialogRef.value.changeDialogStatus()
}

const onJumpLink = () => {
  router.push({
    name: props.moduleTypeInfo?.value === 'TREASURY_BALANCE' ? 'entity-accounts-treasury' : 'reports'
  })
}

/**
 * 编辑Chart表格
 * @param chartData 图表数据
 */
const onEditChartItem = (editData: any) => {
  addChartWidgetDialogRef.value.changeDialogStatus(editData?.data, editData?.timeRange)
}

/**
 * 更新Chart表格
 */
const onUpdateChartList = async () => {
  updateChartListLoading.value = true
  try {
    if (props.moduleTypeInfo.value === DashboardModuleType.treasury.value) {
      await dashboardStore.fetchTreasuryData(entityId.value)
    } else {
      const params = dashboardStore.getCurrentReportFilterInfo(entityId.value)
      await dashboardStore.fetchReportsData(entityId.value, params)
    }
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    updateChartListLoading.value = false
  }
}

/**
 * 保存Chart表格
 */
const onSaveChartItem = async () => {
  onUpdateChartList()
}

/**
 * @description: 切换删除弹窗状态
 */
const onCheckDeleteMessageBox = () => {
  deleteMessageBoxRef.value?.onCheckMessageBoxDialog()
}

/**
 * 删除Chart表格
 * @param chartId 图表数据
 */
const onDeleteChartItem = (chartData: DashboardChartItemTypes) => {
  currentChartData.value = chartData
  onCheckDeleteMessageBox()
}

/**
 * @description: 删除规则确认
 */
const onConfirmDeleteEvent = async () => {
  try {
    deleteLoading.value = true
    await DashboardApi.deleteChartData(entityId.value, currentChartData.value?.chartId)
    ElMessage.success(t('message.deleteSuccess'))
    onCheckDeleteMessageBox()
    onUpdateChartList()
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    deleteLoading.value = false
  }
}

/**
 * 日期筛选变化时触发
 * @param data 日期
 */
const onChangDateTime = async (data: any) => {
  const changePeriodInfo = dashboardStore.getCurrentReportFilterInfo(entityId.value)
  if (!changePeriodInfo) {
    changePeriodStatus.value = !changePeriodStatus.value
  }
  if (changePeriodInfo?.period !== data?.period) {
    changePeriodStatus.value = !changePeriodStatus.value
  }
  const params: ReportChartsFilterTypes = { entityId: entityId.value, dateRange: [], period: '', ...data }
  dashboardStore.saveReportChartsFilterInfo(params)
  onUpdateChartList()
}
</script>

<style lang="scss" scoped>
.elv-dashboard-module-nav {
  margin-top: 25px;

  .elv-dashboard-module-nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .elv-dashboard-module-nav-add-chart-btn {
      width: 102px;
      height: 32px;
      border: 1px solid #c8d6fa;
      color: #1753eb;
      font-size: 13px;
      font-weight: 500;

      svg {
        margin-right: 6px;
      }
    }
  }

  .elv-dashboard-module-nav-summary {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .elv-dashboard-module-nav-chart-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-top: 16px;
  }

  .elv-dashboard-module-nav-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 231px;
    margin-top: 16px;
    color: #636b75;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 8px;
    background: #f9fafb;

    p {
      margin-top: 16px;
    }

    span {
      color: #1753eb;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-decoration-line: underline;
      cursor: pointer;
      margin-left: 6px;
    }
  }
}
</style>
