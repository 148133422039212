<template>
  <div class="elv-dashboard-component-vertical-bar-chart-container">
    <div class="elv-dashboard-component-vertical-bar-chart-container-panel">
      <div
        ref="verticalBarChartRef"
        class="elv-dashboard-component-vertical-bar-pie-chart"
        :style="{ width: props.chartWidth, height: props.chartHeight }"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import echarts from '@/plugins/echarts'
import { ref, onBeforeUnmount } from 'vue'
import { fieldValueFormat } from '@/lib/utils'
import { useEntityStore } from '@/stores/modules/entity'
import { DashboardChartItemTypes } from '#/DashboardTypes'
import { useDashboardStore } from '@/stores/modules/dashboard'

const props = defineProps({
  data: {
    type: Object as () => DashboardChartItemTypes | null,
    required: true
  },
  chartWidth: {
    type: String,
    default: '100%'
  },
  chartHeight: {
    type: String,
    default: '290px'
  },
  timeRange: {
    type: String,
    required: true
  }
})

type CharItemType = {
  label: string
  date: string
  value: number
  children: { label: string; date: string; value: number; isOther: boolean }[]
}

let chartInstance: any = null
const verticalBarChartRef = ref(null)
const chartItemList = ref<Array<CharItemType>>([])

const route = useRoute()
const entityStore = useEntityStore()
const dashboardStore = useDashboardStore()
const { entityDetail } = storeToRefs(entityStore)

const entityId = computed(() => {
  return route.params?.entityId as string
})

const dateFilter: any = computed(() => {
  return dashboardStore.getCurrentReportFilterInfo(entityId.value) || {}
})

const axisLabelCount = computed(() => {
  if (props.data?.sourceType === 'TREASURY_BALANCE') {
    switch (props.data?.config?.timeRange) {
      case 'THIS_QUARTER':
        return 12
      default:
        return 5
    }
  }
  switch (dateFilter.value?.period) {
    case 'YEAR':
      if (props?.timeRange === 'VIEW_BY_DAY') {
        return 30
      }
      return 0
    case 'QUARTER':
      if (props?.timeRange === 'VIEW_BY_MONTH') {
        return 0
      }
      return 12
    case 'DAY':
      return 0
    default:
      return 6
  }
})

const resetChartData = () => {
  if (props.data && Array.isArray(props.data.chartValueList)) {
    const list: CharItemType[] = props.data.chartValueList.map((item) => {
      const timerDate = typeof item.date === 'string' ? item.date : JSON.stringify(item.date) || ''
      return {
        date: timerDate || '',
        label: dayjs(timerDate).format('MMM D'),
        value: item.totalAmountFC,
        children: []
      }
    })
    chartItemList.value = list
  } else {
    chartItemList.value = []
  }
}

const initChart = () => {
  if (!verticalBarChartRef.value) return
  echarts?.dispose(verticalBarChartRef.value)
  chartInstance = echarts.init(verticalBarChartRef.value)
  window.addEventListener('resize', () => {
    if (chartInstance && !chartInstance?.isDisposed()) {
      chartInstance.resize()
    }
  })
  const dashboardLineOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      padding: 12,
      borderWidth: 0,
      formatter: (params: any) => {
        return `<span>${params?.[0]?.axisValue}</span><p>${fieldValueFormat(
          params?.[0]?.value,
          {
            dollar: true,
            symbol: `${
              entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `
            }`
          },
          'NUMBER'
        )}</p>`
      },
      className: 'elv-dashboard-chart-tooltip'
    },
    xAxis: {
      show: true,
      type: 'category',
      data: chartItemList.value.map((item) => {
        return {
          value: item.label,
          textStyle: {
            color: '#838D95',
            fontWeight: 400,
            fontFamily: 'Barlow',
            fontSize: 11
          }
        }
      }),
      axisLine: {
        show: false // 不显示坐标轴线
      },
      axisTick: { show: false }, // 不显示坐标轴刻度线
      axisLabel: {
        interval: axisLabelCount.value,
        // 坐标轴标签样式设置
        formatter(value: string | number | Date) {
          if (props.data?.sourceType !== 'TREASURY_BALANCE') {
            switch (props?.timeRange) {
              case 'VIEW_BY_QUARTER':
                return dayjs(value).format('[Q]Q')
              case 'VIEW_BY_MONTH':
                return dayjs(value).format('MMM')
              default:
                return dayjs(value).format('MMM DD')
            }
          }
          return dayjs(value).format('MMM DD')
        }
      }
    },
    yAxis: {
      axisLabel: {
        // 坐标轴标签样式设置
        formatter(value: number) {
          return fieldValueFormat(
            value,
            {
              dollar: true,
              symbol: `${
                entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `
              }`
            },
            'NUMBER'
          )
        }
      }
    },
    series: [
      {
        type: 'bar',
        data: chartItemList.value.map((item) => item.value),
        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [
              {
                offset: 0,
                color: '#ACC1F8'
              },
              {
                offset: 1,
                color: '#2F64EE'
              }
            ]
          }
        },
        barWidth: 5.5
      }
    ],
    grid: {
      left: '1%',
      right: '1%',
      top: '8%',
      bottom: '5%',
      containLabel: true // 使得图表区域自动适应标签
    }
  }
  if (chartInstance) {
    chartInstance.clear()
  }
  chartInstance.setOption(dashboardLineOption)
}

watch(
  () => props.data,
  () => {
    resetChartData()
    nextTick(() => {
      initChart()
    })
  },
  { immediate: true, deep: true }
)

onBeforeUnmount(() => {
  if (chartInstance) {
    chartInstance.dispose()
  }
})
</script>

<style lang="scss">
.elv-dashboard-component-vertical-bar-chart-container {
  height: 255px;
  display: flex;
  justify-content: center;
  position: relative;

  .elv-dashboard-component-vertical-bar-chart-container-panel {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }
}
</style>
