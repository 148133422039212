<template>
  <el-scrollbar>
    <div class="elv-dashboard-header">
      <div class="elv-dashboard-header-left">
        <h3 class="elv-dashboard-header-left-title">{{ props.title }}</h3>
        <div v-if="props.isShowScreen" class="elv-dashboard-header-filter">
          <div class="elv-dashboard-screening-item">
            <span class="elv-dashboard-screening-item__label">{{ t('common.period') }}:</span>
            <el-select
              v-model="periodValue"
              placeholder=" "
              popper-class="elv-dashboard-screening-popper"
              class="elv-dashboard-screening-period"
              @change="onChangePeriod"
            >
              <el-option v-for="item in periodOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </div>
          <div class="elv-dashboard-screening-item">
            <span class="elv-dashboard-screening-item__label">{{ t('common.dateRange') }}:</span>
            <ELvDatePicker
              ref="datePickerRef"
              v-model:value="dateValue"
              type="date"
              :allowClear="false"
              :picker="periodValue"
              :disabledDate="timePickerDisabledDate"
              @change="onRangeChange"
            />
          </div>
          <div class="elv-dashboard-screening-item">
            <div class="elv-dashboard-header-info">
              {{
                unit === 'individuals'
                  ? entityStore.entityDetail?.defaultCurrency
                  : $t('common.reportUnit', {
                      unit: $t(`common.${unit}`),
                      currency: entityStore.entityDetail?.defaultCurrency
                    })
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import { useEntityStore } from '@/stores/modules/entity'
import { useDashboardStore } from '@/stores/modules/dashboard'
import ELvDatePicker from '@/components/Base/ELvDatePicker/index.vue'

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  isShowScreen: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['onChangDateTime'])

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const dashboardStore = useDashboardStore()
const { entityDetail } = storeToRefs(entityStore)

const datePickerRef = ref()
const dateValue = ref()
const periodValue = ref('month')

const periodOptions = ref([
  {
    value: 'date',
    label: t('common.day')
  },
  {
    value: 'month',
    label: t('common.Month')
  },
  {
    value: 'quarter',
    label: t('common.quarter')
  },
  {
    value: 'year',
    label: t('common.year')
  }
])
const dateFormat = 'YYYY-MM-DD'

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const unit = computed(() => {
  return entityDetail.value?.unit ? entityDetail.value?.unit.toLocaleLowerCase() : 'millions'
})

const timePickerDisabledDate = (time: Date) => {
  if (entityStore.entityDetail?.progress?.reportDoneTime) {
    switch (periodValue.value) {
      case 'month':
        return dayjs(time).isAfter(dayjs(entityStore.entityDetail?.progress?.reportDoneTime).subtract(1, 'month'))
      case 'quarter':
        return dayjs(time).isAfter(dayjs(entityStore.entityDetail?.progress?.reportDoneTime).subtract(1, 'quarter'))
      case 'year':
        return dayjs(time).isAfter(dayjs(entityStore.entityDetail?.progress?.reportDoneTime).subtract(1, 'year'))
      default:
        return dayjs(time).isAfter(dayjs(entityStore.entityDetail?.progress?.reportDoneTime).subtract(1, 'day'))
    }
  }
  switch (periodValue.value) {
    case 'month':
      return dayjs(time).isAfter(dayjs().subtract(1, 'month'))
    case 'quarter':
      return dayjs(time).isAfter(dayjs().subtract(1, 'quarter'))
    case 'year':
      return dayjs(time).isAfter(dayjs().subtract(1, 'year'))
    default:
      return dayjs(time).isAfter(dayjs().subtract(1, 'day'))
  }
}

const onRangeChange = () => {
  let dateRange = [dateValue.value]
  if (periodValue.value !== 'date') {
    switch (periodValue.value) {
      case 'month':
        dateRange = [
          dayjs(dateValue.value).startOf('month').format(dateFormat),
          dayjs(dateValue.value).endOf('month').format(dateFormat)
        ]
        break
      case 'quarter':
        dateRange = [
          dayjs(dateValue.value).startOf('quarter').format(dateFormat),
          dayjs(dateValue.value).endOf('quarter').format(dateFormat)
        ]
        break
      case 'year':
        dateRange = [
          dayjs(dateValue.value).startOf('year').format(dateFormat),
          dayjs(dateValue.value).endOf('year').format(dateFormat)
        ]
        break
      default:
        break
    }
  }
  const params = {
    period: periodValue.value === 'date' ? 'DAY' : periodValue.value.toLocaleUpperCase(),
    dateRange
  }
  emit('onChangDateTime', params)
  dashboardStore.saveReportChartsFilterInfo({ entityId: entityId.value, ...params })
}

const onInitDateTime = () => {
  if (entityStore.entityDetail?.progress?.reportDoneTime) {
    const reportDoneTime = dayjs
      .tz(entityStore.entityDetail?.progress?.reportDoneTime, entityStore.entityDetail?.timezone)
      .subtract(1, 'month')
      .format('YYYY-MM-DD')
    dateValue.value = reportDoneTime
  } else {
    dateValue.value = dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
  }
}

const onChangePeriod = () => {
  dateValue.value = undefined
  let startDate
  let endDate
  let dateRange: any
  switch (periodValue.value) {
    case 'date':
      if (entityStore.entityDetail?.progress?.reportDoneTime) {
        const reportDoneTime = dayjs
          .tz(entityStore.entityDetail?.progress?.reportDoneTime, entityStore.entityDetail?.timezone)
          .subtract(1, 'day')
          .format(dateFormat)
        dateValue.value = reportDoneTime
      } else {
        startDate = dayjs().subtract(1, 'day')
        dateValue.value = dayjs(startDate).format('YYYY-MM-DD')
      }
      dateRange = [dateValue.value]
      break
    case 'month':
      if (entityStore.entityDetail?.progress?.reportDoneTime) {
        const reportDoneTime = dayjs
          .tz(entityStore.entityDetail?.progress?.reportDoneTime, entityStore.entityDetail?.timezone)
          .subtract(1, 'month')
          .format('YYYY-MM-DD')
        console.log(reportDoneTime)
        dateValue.value = reportDoneTime
        dateRange = [
          dayjs(reportDoneTime).startOf('month').format('YYYY-MM-DD'),
          dayjs(reportDoneTime).endOf('month').format('YYYY-MM-DD')
        ]
      } else {
        dateValue.value = dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
        dateRange = [
          dayjs().subtract(1, 'month').startOf('month').startOf('day').format('YYYY-MM-DD'),
          dayjs().subtract(1, 'month').endOf('month').endOf('day').format('YYYY-MM-DD')
        ]
      }
      break
    case 'year':
      if (entityStore.entityDetail?.progress?.reportDoneTime) {
        const reportDoneTime = dayjs
          .tz(entityStore.entityDetail?.progress?.reportDoneTime, entityStore.entityDetail?.timezone)
          .subtract(1, 'year')
          .format('YYYY-MM-DD')
        dateValue.value = reportDoneTime
        dateRange = [
          dayjs(reportDoneTime).startOf('year').format('YYYY-MM-DD'),
          dayjs(reportDoneTime).endOf('year').format('YYYY-MM-DD')
        ]
      } else {
        startDate = dayjs().subtract(1, 'year').startOf('year').startOf('day')
        endDate = dayjs().subtract(1, 'year').endOf('year').endOf('day')
        dateValue.value = dayjs(startDate).format('YYYY-MM-DD')
        dateRange = [dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD')]
      }
      break
    default:
      if (entityStore.entityDetail?.progress?.reportDoneTime) {
        const reportDoneTime = dayjs
          .tz(entityStore.entityDetail?.progress?.reportDoneTime, entityStore.entityDetail?.timezone)
          .subtract(1, 'quarter')
          .format('YYYY-MM-DD')
        dateValue.value = reportDoneTime
        // 计算所选季度的第一天和最后一天
        const [year, quarter] = dayjs(reportDoneTime).format('YYYY-[Q]Q').split('-Q').map(Number)
        const startMonth = (quarter - 1) * 3
        startDate = dayjs(`${year}-${startMonth + 1}-01`)
          .startOf('day')
          .format('YYYY-MM-DD')
        endDate = dayjs
          .tz(entityStore.entityDetail?.progress?.reportDoneTime, entityStore.entityDetail?.timezone)
          .subtract(1, 'quarter')
          .endOf('quarter')
          .endOf('day')
      } else {
        startDate = dayjs().subtract(1, 'quarter').startOf('quarter').date(1).startOf('day')
        endDate = dayjs().subtract(1, 'quarter').endOf('quarter').endOf('day')
      }
      dateRange = [dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD')]
      break
  }
  const params = {
    period: periodValue.value === 'date' ? 'DAY' : periodValue.value.toLocaleUpperCase(),
    dateRange
  }
  emit('onChangDateTime', params)
  dashboardStore.saveReportChartsFilterInfo({ entityId: entityId.value, ...params })
}

defineExpose({ dateValue, periodValue })

onMounted(() => {
  periodValue.value = 'month'
  onInitDateTime()
})
</script>
<style lang="scss" scoped>
.elv-dashboard-header {
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.elv-dashboard-header-left {
  display: flex;
  align-items: center;

  .elv-dashboard-header-left-title {
    font-family: 'Plus Jakarta Sans';
    font-size: 24px;
    font-weight: 600;
    line-height: 25px;
  }

  .elv-dashboard-header-filter {
    display: flex;
    align-items: center;
    margin-right: 22px;
    margin-left: 24px;
    padding-top: 6px;
  }
}
</style>

<style lang="scss">
.elv-dashboard-screening-item {
  padding-left: 16px;
  margin-left: 16px;
  border-left: 1px solid #dde1e6;
  display: flex;
  height: 15px;
  align-items: center;

  &:first-child {
    border-left: none;
    margin-left: 0px;
    padding-left: 0px;
  }

  .elv-dashboard-screening-item__label {
    color: #838d95;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: fit-content;
    margin-right: 8px;
    white-space: nowrap;
  }

  .elv-dashboard-header-info {
    color: #838d95;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .el-select {
    height: 15px;
    width: fit-content;

    .el-input {
      height: 15px;

      .el-input__wrapper {
        border: none !important;
        box-shadow: none !important;
        padding: 0;
      }

      .el-input__inner {
        color: #1e2024;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-width: fit-content;
      }

      .el-input__suffix {
        .el-icon {
          margin-left: 4px;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    .el-select__wrapper {
      height: 15px;
      min-height: 15px;
      border: none !important;
      box-shadow: none !important;
      padding: 0;
      padding-left: 8px;
      gap: 0px;

      .el-select__selection {
        width: fit-content;

        .el-select__placeholder {
          position: relative;
          top: 0;
          transform: none;
          color: #1e2024;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: fit-content;
          overflow: visible;
        }
      }

      .el-select__suffix {
        .el-icon {
          color: #1e2024;
          margin-left: 4px;

          svg {
            width: 12px;
            height: 12px;
            fill: #0e0f11;
          }
        }
      }
    }
  }

  .elv-date-picker {
    input {
      width: auto;
      min-width: 1ch;
      max-width: calc(100% - 30px);
      font-size: 12px;
      font-family: 'Plus Jakarta Sans';
      color: #1e2024;
      font-weight: 400;
    }

    .ant-picker-suffix {
      margin-left: 1px;
    }
  }
}

.el-popper.elv-reports-regenerate-tips {
  width: 196px;
  padding: 5px 10px 8px;
  box-sizing: border-box;

  .elv-table-tips-content {
    color: #fff;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    padding: 0px;
    word-break: break-word;
  }
}

.elv-dashboard-screening-popper.el-popper.el-select__popper {
  transform: translateY(-6.5px);

  .el-select-dropdown__item {
    height: 32px;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &.hover,
    &.is-hovering {
      background: #f9fafb;
    }

    &.selected,
    &.is-selected {
      color: #1753eb;
      font-weight: 500;
    }

    &.is-disabled {
      background-color: #f5f7fa !important;
    }
  }
}
</style>
