<template>
  <div class="elv-dashboard-chart-item">
    <div class="elv-dashboard-chart-item-header">
      <span class="elv-dashboard-chart-item-title"
        >{{ props.chartData.name }}
        <span
          v-if="props.dashboardModuleType === DashboardModuleType.reports.value"
          class="elv-dashboard-chart-item-title-date"
          >{{ chartUpdateDataTime }}</span
        ></span
      >
      <template
        v-if="!(['MEMBER', ''].includes(currentEntityPermission?.role) && !currentEntityPermission?.dashboard?.update)"
      >
        <el-dropdown popper-class="elv-dashboard-chart-item-header-dropdown-popper">
          <SvgIcon
            class="elv-dashboard-chart-item-header-dropdown-link"
            name="more-dian"
            width="14"
            height="14"
            :fill="'#AAAFB6'"
          ></SvgIcon>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="editChartItem">{{ t('button.edit') }}</el-dropdown-item>
              <el-dropdown-item @click="deleteChartItem">{{ t('button.delete') }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <SvgIcon width="20" height="20" name="draggable" class="elv-dashboard-chart-item-title-draggable__icon" />
      </template>
    </div>
    <div class="elv-dashboard-chart-item-screening">
      <ChartConditionSelect
        v-model:timeModel="selectTimerRange"
        v-model:currencyModel="selectCurrencyList"
        :dashboardModuleType="props.dashboardModuleType"
        :currencyList="props.chartData.configCurrencyList"
        :showFilterDateRange="filterCondition.showFilterDataRange"
        :showFilterCurrency="filterCondition.showFilterCurrency"
        :showFilterViewBy="filterCondition.showFilterViewBy"
        :targetType="props.chartData.targetType"
        :showTrend="filterCondition.showTrend"
        :showDistribution="filterCondition.showDistribution"
        @onChangeSelectTimeRange="queryPreviewChartData"
        @onChangeSelectCurrency="onChangeSelectCurrency"
      />
      <ChangeChartTypeButton
        class="elv-dashboard-chart-item-screening-button"
        :chartType="currentShowChartType"
        :chartData="props.chartData"
        @onChangeSelectChartType="onChangeSelectChartType"
      />
    </div>
    <component
      :is="currentChartComponent"
      v-if="currentChartData.chartValueList?.length"
      v-loading="loadPreviewLoading"
      :data="currentChartData"
      :timeRange="selectTimerRange"
    ></component>
    <div v-else v-loading="loadPreviewLoading" class="elv-dashboard-chart-item-empty">
      <SvgIcon name="dashboard-empty" :width="48" :height="48" fill="#DDE1E6" />
      <span>{{ t('common.noData') }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { dayjs, ElMessage } from 'element-plus'
import DashboardApi from '@/api/DashboardApi'
import PieChart from './ChartWidget/PieChart.vue'
import LineChart from './ChartWidget/LineChart.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { DashboardChartItemTypes } from '#/DashboardTypes'
import { useDashboardStore } from '@/stores/modules/dashboard'
import ChangeChartTypeButton from './ChangeChartTypeButton.vue'
import VerticalBarChart from './ChartWidget/VerticalBarChart.vue'
import HorizontalBarChart from './ChartWidget/HorizontalChart.vue'
import ChartConditionSelect from './ChartConditionSelect.vue'
import {
  DashboardChartType,
  DashboardModuleType,
  ReportSummaryIndicatorList,
  timeGranularity,
  TreasurySummaryIndicatorList,
  viewByList
} from '@/config/dashboard'
import { cloneDeep } from 'lodash-es'

const props = defineProps({
  dashboardModuleType: {
    type: String,
    require: true,
    default: ''
  },
  chartData: {
    type: Object as () => DashboardChartItemTypes,
    require: true,
    default: {} as DashboardChartItemTypes
  },
  changePeriodStatus: {
    type: Boolean,
    require: true
  }
})

const emit = defineEmits(['onDeleteChartItem', 'onEditChartItem'])

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const dashboardStore = useDashboardStore()

const currentShowChartType = ref('')
const loadPreviewLoading = ref(false)
const selectCurrencyList = ref(['All_Currency'])
const currentChartData = ref<DashboardChartItemTypes>({} as DashboardChartItemTypes)
const selectTimerRange = ref(timeGranularity[0].value)

const componentMap = {
  [DashboardChartType.lineChart.type]: LineChart,
  [DashboardChartType.pieChart.type]: PieChart,
  [DashboardChartType.barVerticalChart.type]: VerticalBarChart,
  [DashboardChartType.barHorizontalChart.type]: HorizontalBarChart
}

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const currentChartComponent = computed(() => {
  return componentMap[currentShowChartType.value] || LineChart
})

const chartUpdateDataTime = computed(() => {
  const filterInfo = dashboardStore.getCurrentReportFilterInfo(entityId.value)
  if (!filterInfo) return ''
  const dateRange = props.chartData?.showDate || filterInfo?.dateRange
  if (!dateRange?.length) {
    return ''
  }
  const formatDate = dateRange.map((item) => {
    return dayjs(item).format('YYYY/MM/DD')
  })
  return `(${formatDate.join('-')})`
})

const filterCondition = computed(() => {
  const metricList =
    props.dashboardModuleType === DashboardModuleType.treasury.value
      ? TreasurySummaryIndicatorList
      : ReportSummaryIndicatorList
  const currentMetric = metricList.find((item) => item.value === props.chartData.metric)
  return {
    showFilterDataRange: props.chartData.targetType === 'TREND',
    showFilterCurrency: currentMetric?.showCurrency || false,
    showFilterViewBy: currentMetric?.showViewBy || false,
    showTrend: currentMetric?.showTrend || false,
    showDistribution: currentMetric?.showDistribution || false
  }
})

/**
 * 初始化日期颗粒度
 */
const initViewByDefaultDate = () => {
  if (props.dashboardModuleType === DashboardModuleType.treasury.value) {
    selectTimerRange.value = props.chartData?.config?.timeRange || timeGranularity[0].value
  } else {
    selectTimerRange.value = viewByList[2].value
  }
}

/**
 * 初始化图标展示类型
 */
const initShowChartType = () => {
  Object.keys(DashboardChartType).forEach((key) => {
    const chartType = DashboardChartType[key]
    if (
      chartType.dashboardType.includes(props.chartData.showType) &&
      chartType.dashboardType.includes(props.chartData.targetType)
    ) {
      currentShowChartType.value = chartType.type
    }
  })
  currentChartData.value = cloneDeep(props.chartData)
  selectCurrencyList.value = props.chartData.config?.currencyList?.length
    ? props.chartData.config?.currencyList
    : ['All_Currency']
  initViewByDefaultDate()
}

/**
 * 监听切换显示图标类型
 * @param chartType 选择的图表类型
 */
const onChangeSelectChartType = (chartType: string) => {
  currentShowChartType.value = chartType
}

/**
 * 删除图表项
 */
const deleteChartItem = () => {
  emit('onDeleteChartItem', props.chartData)
}

/**
 * 编辑图表项
 */
const editChartItem = () => {
  emit('onEditChartItem', { data: props.chartData, timeRange: selectTimerRange.value || '' })
}

/**
 * 获取图表预览数据
 */
const queryPreviewChartData = async () => {
  loadPreviewLoading.value = true
  try {
    if (props.dashboardModuleType === DashboardModuleType.treasury.value) {
      const treasuryData = await DashboardApi.previewTreasuryChartData(entityId.value, {
        targetType: props.chartData.targetType,
        config: {
          timeRange: selectTimerRange.value,
          currencyList: selectCurrencyList.value.filter((item) => item !== 'All_Currency')
        }
      })

      if (currentChartData.value) {
        currentChartData.value.chartValueList = treasuryData.data
      }
    } else {
      const reportFilterInfo: any = dashboardStore.getCurrentReportFilterInfo(entityId.value)
      const reportData = await DashboardApi.previewReportsChartData(entityId.value, {
        targetType: props.chartData.targetType,
        metric: props.chartData.metric,
        dateRange: reportFilterInfo?.dateRange || [],
        period: reportFilterInfo?.period?.toLocaleUpperCase() || 'MONTH',
        config: {
          viewTimeRange: selectTimerRange.value,
          currencyList: selectCurrencyList.value.filter((item) => item !== 'All_Currency')
        }
      })
      if (currentChartData.value) {
        currentChartData.value.chartValueList = reportData.data?.chartValueList ?? reportData.data
        currentChartData.value.config.timeRange = selectTimerRange.value
      }
    }
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    loadPreviewLoading.value = false
  }
}

/**
 * 图标预览 切换币种
 * @param currency 币种
 */
const onChangeSelectCurrency = (currencyList: string[]) => {
  if (currencyList.length === 0) {
    selectCurrencyList.value = ['All_Currency']
    queryPreviewChartData()
    return
  }
  if (currencyList[currencyList.length - 1] === 'All_Currency') {
    selectCurrencyList.value = ['All_Currency']
  } else {
    selectCurrencyList.value = currencyList.filter((item) => item !== 'All_Currency')
  }
  queryPreviewChartData()
}

watch(
  () => props.changePeriodStatus,
  () => {
    initViewByDefaultDate()
  }
)

watch(
  () => props.chartData,
  () => {
    initShowChartType()
  },
  {
    immediate: true,
    deep: true
  }
)

// onMounted(initShowChartType)
</script>

<style lang="scss">
.elv-dashboard-chart-item {
  display: flex;
  flex-direction: column;
  background-color: #f9fafb;
  border: 1px solid #edf0f3;
  border-radius: 8px;
  padding: 24px 24px 42px 16px;
  height: 404px;
  box-sizing: border-box;
  position: relative;

  .elv-dashboard-chart-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .elv-dashboard-chart-item-title {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;

      .elv-dashboard-chart-item-title-date {
        margin-left: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #aaafb6;
      }
    }

    .elv-dashboard-chart-item-header-dropdown-link {
      cursor: pointer;
    }

    .elv-dashboard-chart-item-title-draggable__icon {
      fill: #aaafb6;
      position: absolute;
      top: 14px;
      left: 50%;
      transform: rotate(90deg) translateX(-50%);
      cursor: grab;

      &:hover {
        fill: #1753eb;
      }
    }
  }

  .elv-dashboard-chart-item-screening {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 16px;
    height: 28px;

    .elv-dashboard-chart-item-screening-button {
      position: absolute;
      right: 0px;
    }
  }

  .el-loading-mask {
    background-color: #f9fafb;
  }

  .elv-dashboard-chart-item-empty {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      color: #d0d4d9;
      margin-bottom: 8px;
    }

    span {
      color: #636b75;
      text-align: center;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
}

.elv-dashboard-chart-item-header-dropdown-popper {
  .el-dropdown-menu__item:not(.is-disabled):hover {
    color: #1753eb;
    background-color: #ffffff;
  }
}
</style>
